<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Grading Scale</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogGradingScale"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Grading Scale</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Calculate Result Method</th>
                    <th scope="col">Display Result Type</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(grading_scale, i) in grading_scales">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale.type_label }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale.calculate_result_type_label }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ grading_scale.display_result_type_label }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'GradingScaleSkill',
                                  params: { id: grading_scale.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-clipboard-list-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Calculate Methods Management</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="editGradingScale(i, grading_scale.id)"
                                v-if="canAccess('grading-scale-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteGradingScale(grading_scale.id)"
                                v-if="canAccess('grading-scale-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogGradingScale"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form == 'add'" class="headline"
                >Add Grading Scale</span
              >
              <span v-if="type_form == 'update'" class="headline"
                >Edit Grading Scale</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogGradingScale = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Name:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="name_input"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Type:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="types"
                        item-text="label"
                        item-value="value"
                        v-model="type_input"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Calculate Resuft Method:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="calculate_resuft_methods"
                        item-text="label"
                        item-value="value"
                        v-model="calculate_resuft_method_input"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Display Result Type:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="display_result_types"
                        item-text="label"
                        item-value="value"
                        v-model="display_result_type_input"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-row>
                    <v-col cols="3">
                      <label
                        style="font-size: 16px"
                        class="mt-1 p-0 float-right"
                        >Decimal Places:
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="decimal_places_input"
                        type="number"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form == 'add'"
              @click="btnAddOrEditGradingScale(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form == 'update'"
              @click="btnAddOrEditGradingScale(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogGradingScale = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "GradingScale",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      grading_scales: [],
      types: [],
      calculate_resuft_methods: [],
      display_result_types: [],
      dialogGradingScale: false,
      type_form: null,
      name_input: null,
      type_input: null,
      calculate_resuft_method_input: null,
      display_result_type_input: null,
      decimal_places_input: null,
      grading_scale_id: null,
    };
  },
  created() {
    if (this.canAccess("grading-scale-view")) {
      this.getTypes();
      this.getAllGradingScale();
      this.getCalculateResultMethod();
      this.getDisplayResultType();
    } else {
      this.$router.push({ name: "403" });
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllGradingScale() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/grading-scale");
        if (res.status == 200) {
          vm.grading_scales = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    getTypes() {
      let vm = this;
      ApiService.get("prep-app/grading-scale/type").then(function (res) {
        if (res.status == 200) {
          vm.types = res.data;
        }
      });
    },
    getCalculateResultMethod() {
      let vm = this;
      ApiService.get(
        "prep-app/grading-scale/list-calculate-resuft-method"
      ).then(function (res) {
        if (res.status == 200) {
          vm.calculate_resuft_methods = res.data;
        }
      });
    },
    getDisplayResultType() {
      let vm = this;
      ApiService.get("prep-app/grading-scale/list-display-result-type").then(
        function (res) {
          if (res.status == 200) {
            vm.display_result_types = res.data;
          }
        }
      );
    },
    openDiaLogGradingScale() {
      this.type_form = "add";
      this.name_input = null;
      this.type_input = null;
      this.calculate_resuft_method_input = null;
      this.display_result_type_input = null;
      this.decimal_places_input = null;
      this.dialogGradingScale = true;
    },
    editGradingScale(i, grading_scale_id) {
      let data = this.grading_scales[i];
      this.name_input = data.name;
      this.type_input = data.type;
      this.calculate_resuft_method_input = data.calculate_result_type;
      this.display_result_type_input = data.display_result_type;
      this.decimal_places_input = data.decimal_places;
      this.grading_scale_id = grading_scale_id;
      this.type_form = "update";
      this.dialogGradingScale = true;
    },
    btnAddOrEditGradingScale(type) {
      let vm = this;
      let data = {
        name: this.name_input,
        type: this.type_input,
        calculate_result_type: this.calculate_resuft_method_input,
        display_result_type: this.display_result_type_input,
        decimal_places: this.decimal_places_input,
      };
      if (type == 1) {
        ApiService.post("prep-app/grading-scale", data)
          .then(function (res) {
            if (res.status == 200) {
              vm.getAllGradingScale();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogGradingScale = false;
            }
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/grading-scale/" + this.grading_scale_id, data)
          .then(function (res) {
            if (res.status == 202) {
              vm.getAllGradingScale();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogGradingScale = false;
            }
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteGradingScale(grading_scale_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/grading-scale/" + grading_scale_id).then(
            function (res) {
              if (res.status == 200) {
                vm.getAllGradingScale();
              }
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
